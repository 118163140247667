@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
}
.MuiBox-root.css-1qoos23,
.MuiBox-root.css-15gjt96,
.MuiBox-root.css-1qxxafa {
  display: none;
}

/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}

body {
  height: 100%;
  width: 100%;
  background-color: #333;
}


#countdown-number {
  color: black;
    display: inline-block;
    line-height: 40px;
    font-size: 47px;
    width: 100%;
    position: absolute;
    top: 43%;
    right: 0;
    text-align: center;
}

#svg {
  width: 210px;
    height: 280px;
    transform: rotateY(-180deg) rotateZ(-90deg) translateY(10%);
}

#svg circle {
  stroke-dasharray: 623px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 5px;
  stroke: #f5a741;
  fill: none;
}
